<template>
    <div id="nav">
    <v-app-bar
      app
      color="primary"
      dark
    >
      <router-link to="/">OKMSupreme</router-link>
      <v-spacer></v-spacer>
      <router-link to="/about"> About </router-link>
      <router-link to="/items"> Store </router-link>
      <router-link v-if="!user" to="/login"> Log in </router-link> 
      <router-link v-if="!user" to="/register"> Register </router-link> 
      <router-link v-if="user" to="/dashboard"> Dashboard </router-link> 
      <a v-if="user" @click="onLogout"> Logout </a>
    </v-app-bar>
    </div>
</template>

<script>
export default {
  data () {
    return {
      user: this.$store.getters.user
    }
  },
  methods: {
    async onLogout() {
        await fetch(`${process.env.VUE_APP_API_URL}/api/auth/logout`, { method: "POST", credentials: 'include' })
        this.$router.push('/');
    }
  },
  created() {
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'user') {
        this.user = state.user
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
}
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #f0ead6;
  margin: 10px;
  text-decoration: none;
}

#nav a .main {
  background-color: #f0ead6;
}

#nav a.router-link-exact-active {
  color: #ffffff;
}
</style>