<template>
    <v-card
        class="pa-2 ma-3 rounded-lg"
        outlined
        tile
        elevation="1"
        :to="{ name: 'Item', params: { id: item.id }}"
        height="100%"
        >
        <v-row justify="center" align="center" height="100%">
            <v-col xs="8">
                
            <v-card-text>
            <div class="text-subtitle-2">
                {{item.title}}
            </div>
            <br>
            <div class="text-subtitle-3">
                Size {{item.size}}
            </div>
            <div class="text-subtitle-3">
                $ {{item.price}} CAD
            </div>
            </v-card-text>
            </v-col>
            <v-col xs="4">
                <v-img contain max-height="150" max-width="100" :lazy-src="`${item.imageURL}`" :src="`${item.imageURL}`"></v-img>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    props: {
        item: Object
    }
}
</script>