<template>
  <div>
    <v-img
      lazy-src="../assets/banner.jpg"
      src="../assets/banner.jpg"
      class="white--text align-end rounded-lg"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,0.7)"
      >
      <div class="pa-4">
        <h1 class="mb-4">100% Authentic Streetwear</h1>
        <v-btn to='/items' class="primary" style="z-index: 9999">Shop Now</v-btn>
      </div>
    </v-img>
    <v-row class="ma-10">
      <v-col
        xs="12"
        sm="12"
        md="4"
      >
        <v-card class="pa-5" to='/items'>
          <h2>Sneakers</h2>
          <v-icon x-large color="secondary">mdi-shoe-sneaker</v-icon>
        </v-card>
      </v-col>
      <v-col
        xs="12"
        sm="12"
        md="4"
      >
      <v-card class="pa-5" to='/items'>
          <h2>Clothes</h2>
          <v-icon x-large color="secondary">mdi-tshirt-crew</v-icon>
        </v-card>
      </v-col>
      <v-col
        xs="12"
        sm="12"
        md="4"
      >
      <v-card class="pa-5" to='/items'>
          <h2>Other</h2>
          <v-icon x-large color="secondary">mdi-gamepad</v-icon>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-1">
      <v-card class="pa-5" min-width="100%" elevation="0">
        <v-card-title>New Products</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="item in displayItems" :key="item.id" md="4" sm="12" xs="12">
              <ItemCard :item="item"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
  import ItemCard from '../components/ItemCard.vue';
  import { mapGetters } from 'vuex'

  export default {
    name: 'Home',
    data () {
        return {
            loading: false,
            error: null,
        }
    },
    computed: {
        ...mapGetters({
            items: 'items'
        }),
        displayItems () {
          return this.items.slice(1).slice(-3)
        }
    },
    created () {
        this.loading = true;
        this.$store.dispatch("fetchItems", {});
        this.loading = false;
    },
    components: {
        ItemCard
    }
  }
</script>
