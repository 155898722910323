import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: '',
    user: null,
    items: [],
    adminItems: [],
    currentItem: null
  },
  mutations: {
    user (state, user) {
      state.user = user
    },
    adminItems (state, adminItems) {
      state.adminItems = adminItems
    },
    items (state, items) {
      state.items = items
    },
    currentItem (state, item) {
      state.currentItem = item
    },
    error (state, error) {
      state.error = error
    }
  },
  actions: {
    async fetchUser(state) {
      try {
        const res = await fetch(`${process.env.VUE_APP_API_URL}/api/user/`, { credentials: 'include' });
        if (res.status === 200) {
          const json = await res.json();
          state.commit('user', json);
        } else {
          state.commit('user', null);
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchItemsAdmin(state, payload) {
      try {
        let url = `${process.env.VUE_APP_API_URL}/api/item/admin?`;

        for (const key of Object.keys(payload)) {
          for (const item of payload[key]) {
            url += `${key}=${item}&`
          }
        }

        const res = await fetch(url, { credentials: 'include' });
        const json = await res.json();
        state.commit('adminItems', json);
      } catch (e) {
          console.log(e)
      }
    },
    async fetchItems(state, payload) {
      console.log(process.env.VUE_APP_API_URL)
      try {
        let url = `${process.env.VUE_APP_API_URL}/api/item/?`;

        for (const key of Object.keys(payload)) {
          for (const item of payload[key]) {
            url += `${key}=${item}&`
          }
        }

        const res = await fetch(url);
        const json = await res.json();

        state.commit('items', json);
      } catch (e) {
          console.log(e)
      }
    },
    async fetchItem(state, payload) {
      try {
        const res = await fetch(`${process.env.VUE_APP_API_URL}/api/item/${payload.id}`);
        const json = await res.json();
        state.commit('currentItem', json);
      } catch (e) {
          console.log(e)
      }
    },
    async deleteItem(state, payload) {
      try {
        const res = await fetch(`${process.env.VUE_APP_API_URL}/api/item/${payload.id}`, { method: 'DELETE', credentials: 'include' });
        
        if (res.status === 200) {
          const items = state.getters.items;
          state.commit('items', items.filter(item => item.id !== payload.id))
        }
      } catch (e) {
          console.log(e)
      }
    },
    async addItem(state, payload) {
      try {
        let formData = new FormData();
        for (const key of Object.keys(payload)) {
          formData.append(key, payload[key]);
        }

        const requestOptions = {
            method: "POST",
            body: formData,
            credentials: 'include'
        };

        const res = await fetch(`${process.env.VUE_APP_API_URL}/api/item/`, requestOptions);
        if (res.status === 200) {
          state.dispatch("fetchItemsAdmin", {});
        } else {
          const j = await res.json();
          state.dispatch("setError", { error: j.error})
        }
        
      } catch (e) {
        console.log(e)
      }
    },
    async updateItem(state, payload) {
      try {
        const id = payload.id;

        delete payload.id;

        let formData = new FormData();
        for (const key of Object.keys(payload)) {
          formData.append(key, payload[key]);
        }

        const requestOptions = {
            method: "PUT",
            body: formData,
            credentials: 'include'
        };

        const res = await fetch(`${process.env.VUE_APP_API_URL}/api/item/${id}`, requestOptions);
        if (res.status === 200) {
          state.dispatch("fetchItemsAdmin", {});
        } else {
          const j = await res.json();
          state.dispatch("setError", { error: j.error})
        }
        
      } catch (e) {
        console.log(e)
      }
    },
    setError (state, payload) {
      state.commit("error", payload.error)
    }
  },
  modules: {
  },
  getters: {
    user: state => state.user,
    items: state => state.items,
    adminItems: state => state.adminItems,
    currentItem: state => state.currentItem,
    error: state => state.error
  }
})
