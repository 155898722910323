<template>
  <v-app>
    <Navbar />
    <v-main>
      <v-row no-gutters justify="center">
        <v-col
          class="pa-4"
          sm="10"
          md="8"
          lg="6"
        >
          <router-view/>
        </v-col>
      </v-row>
    </v-main>
    <v-snackbar v-model="snackbar">
      {{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="dismiss"
        >
          Dismiss
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Navbar from './components/Navbar.vue';

export default {
  computed: {
    ...mapGetters({
        error: 'error'
    }),
    snackbar: {
      get() {
        return this.error && this.error.length
      },
      set() {
      }
    }
  },
  name: 'App',
  components: {
    Navbar
  },
  methods: {
    dismiss() {
      this.$store.dispatch("setError", { error: "" })
    }
  }
};
</script>